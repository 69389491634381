export default [
	{
		path: '/emails',
		name: 'emails.index',
		component: () => import(/* webpackChunkName: "emails" */ '../views/Marketing/Emails.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/add-emails',
		name: 'emails.store',
		component: () => import(/* webpackChunkName: "addEmails" */ '../views/Marketing/AddEmails.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/emails/:id/edit',
		name: 'emails.edit',
		component: () => import(/* webpackChunkName: "editEmail" */ '../views/Marketing/EditEmail.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/newsletter',
		name: 'newsletter.index',
		component: () => import(/* webpackChunkName: "newsletter" */ '../views/Marketing/Newsletter.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/promotion',
		name: 'promotion.index',
		component: () => import(/* webpackChunkName: "promotion" */ '../views/Marketing/Promotion.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/product-showcase',
		name: 'productShowcase.index',
		component: () => import(/* webpackChunkName: "proeuctShowcase" */ '../views/Marketing/ProductShowcase.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
]
