export default [
	{
		path: '/report:subscriber_id?',
		name: 'report.index',
		component: () => import(/* webpackChunkName: "report" */ '../views/Report/Report.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/statementPDF/:id',
		name: 'StatementPDF',
		component: () => import(/* webpackChunkName: "statementPDF" */ '../views/Report/StatementPDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
	{
		path: '/clientAccountAgingPDF/:id',
		name: 'ClientAccountAgingPDF',
		component: () => import(/* webpackChunkName: "clientAccountAgingPDF" */ '../views/Report/ClientAccountAgingPDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
	{
		path: '/supplierAccountAgingPDF/:id',
		name: 'SupplierAccountAgingPDF',
		component: () => import(/* webpackChunkName: "supplierAccountAgingPDF" */ '../views/Report/SupplierAccountAgingPDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
	{
		path: '/sales-taxesPDF/:id',
		name: 'SalesTaxPDF',
		component: () => import(/* webpackChunkName: "salesTaxPDF" */ '../views/Report/SalesTaxPDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
	{
		path: '/salesTaxReportByFilenamePDF/:id',
		name: 'salesTaxReportByFilenamePDF',
		component: () => import(/* webpackChunkName: "salesTaxReportByFilenamePDF" */ '../views/Report/SalesTaxReportByFilenamePDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
]
