export default [
    {
        path: '/client',
        name: 'client.index',
        component: () => import(/* webpackChunkName: "client" */ '../views/Client/Client.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/add-client',
        name: 'client.store',
        component: () => import(/* webpackChunkName: "addClient" */ '../views/Client/AddClient.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/client/:id/edit',
        name: 'client.edit',
        component: () => import(/* webpackChunkName: "editClient" */ '../views/Client/EditClient.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
	{
		path: '/client-transaction',
		name: 'clientTransaction.index',
		component: () => import(/* webpackChunkName: "clientTransaction" */ '../views/Client/ClientTransaction.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
]
